import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_AUDIO_CONTEXT:
      return {
        audioCtx: action.context,
      };
    default:
      return state;
  }
};

export default reducer;
