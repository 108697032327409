import * as actionTypes from "../actions/actionTypes";

const initialState = {
  keyPressArray: [],
  completedConditionArray: ["init"],
  keyEnabledArray: Array(222).fill(true),
  numCardsCompleted: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_KEY_PRESS:
      return {
        ...state,
        keyPressArray: [...state.keyPressArray, action.payload],
      };
    case actionTypes.SET_COMPLETED_CONDITION_ARRAY:
      return {
        ...state,
        completedConditionArray: action.payload,
      };
    case actionTypes.SHIFT_KEY_PRESS_ARRAY:
      let updatedKeyPressArray = state.keyPressArray;
      updatedKeyPressArray = updatedKeyPressArray.slice(1);
      return {
        ...state,
        keyPressArray: updatedKeyPressArray,
      };
    case actionTypes.RESET_KEY_PRESS_ARRAY: {
      return {
        ...state,
        keyPressArray: [],
      };
    }
    case actionTypes.ENABLE_KEY_IN_KEY_ENABLED_ARRAY: {
      const updatedKeyEnabledArray = state.keyEnabledArray;
      updatedKeyEnabledArray[action.keyCode] = true;
      return {
        ...state,
        keyEnabledArray: updatedKeyEnabledArray,
      };
    }
    case actionTypes.DISABLE_KEY_IN_KEY_ENABLED_ARRAY: {
      const updatedKeyEnabledArray = state.keyEnabledArray;
      updatedKeyEnabledArray[action.keyCode] = false;
      return {
        ...state,
        keyEnabledArray: updatedKeyEnabledArray,
      };
    }
    case actionTypes.INCREMENT_NUM_CARDS_COMPLETED: {
      const numCardsCompleted = state.numCardsCompleted + 1;
      return {
        ...state,
        numCardsCompleted,
      };
    }
    case actionTypes.SET_CARDS_COMPLETED_TO_ZERO: {
      return {
        ...state,
        numCardsCompleted: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
