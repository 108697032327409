import React, { useState, createContext, useEffect } from "react";

// This component is rendered when an audioContext is needed
// The audioContext will be held in component STATE and NOT
// inside redux.  All event listeners that will play audio
// will be registered on children of this element

export const ReactAudioContext = createContext();

export const AudioContextProvider = (props) => {
  const [audioCtx, setAudioContext] = useState();
  useEffect(() => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    setAudioContext(audioContext);

    return () => {
      console.log("closing audio context");
      audioContext.close();
    };
  }, []);

  return (
    <ReactAudioContext.Provider value={audioCtx}>
      {props.children}
    </ReactAudioContext.Provider>
  );
};
