import * as actionTypes from "../actions/actionTypes";

// CREATE MESSAGE

export const createMessage = (msg) => {
  return {
    type: actionTypes.CREATE_MESSAGE,
    payload: msg,
  };
};
