import axios from "axios";
import axiosInstance from "../../axiosAPI";
import { BASE_URL } from "../../axiosAPI";
import decode from "jwt-decode";
import * as actionTypes from "./actionTypes";
import { createMessage } from "./messages";
import { getStudentInfo } from "./student";
import history from "../../history";

export const loginStart = (username, password, ownProps) => async (
  dispatch
) => {
  dispatch({ type: actionTypes.LOGIN_START });
  try {
    console.log("issuing login call");
    // make a call to the api with login credentials
    const response = await axios.post(`${BASE_URL}token/obtain/`, {
      username,
      password,
    });
    console.log(response);
    if (response.status == 200 || response.status == 201) {
      // TODO this is NOT DRY.  Same code in refereshTokenStart except for ownProps.history.push
      const decoded = decode(response.data.access);
      console.log(decoded);

      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);
      axiosInstance.defaults.headers["Authorization"] =
        "Bearer " + response.data.access;
      dispatch({
        type: actionTypes.AUTH_SUCCESS,
        username: decoded.username,
        id: decoded.id,
      });
      console.log(decoded.user_id);
      dispatch(getStudentInfo(decoded.user_id));

      ownProps.history.push("/lessons/");
      dispatch(
        createMessage({ welcomeBack: `Welcome back ${decoded.username}` })
      );
    }
  } catch (error) {
    dispatch(authFail());
    dispatch(
      createMessage({ loginFail: `Usename and Password did not match` })
    );
  }
};

export const signupStart = (
  username,
  password,
  email,
  // classCode,
  ownProps
) => async (dispatch) => {
  dispatch({ type: actionTypes.SIGNUP_START });
  try {
    // make a call to the api with signup credentials
    const response = await axios.post(`${BASE_URL}user/create/`, {
      username,
      password,
      email,
      // classCode,
    });
    // if successful, log the user in
    if (response.status == 201) {
      dispatch({ type: actionTypes.SIGNUP_SUCCESS });
      dispatch(loginStart(username, password, ownProps));
    }
  } catch (error) {
    let dataArray = Object.keys(error.response.data);
    const field = dataArray[0];
    dispatch(createMessage({ loginFail: `${error.response.data[field][0]}` }));
    dispatch({ type: actionTypes.SIGNUP_FAIL });
    // TODO handle errors better
  }
};

export const refreshTokenStart = () => async (dispatch) => {
  try {
    // TODO
    // make a call to the api with a token to refresh

    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      // TODO call refreshTokenFail action
      return history.push("/");
    }
    const response = await axiosInstance.post("/token/refresh/", {
      refresh: refreshToken,
    });

    // TODO this is NOT DRY.  Same code in loginStart
    if (response.status === 201 || response.status === 200) {
      const decoded = decode(response.data.access);
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);
      axiosInstance.defaults.headers["Authorization"] =
        "Bearer " + response.data.access;
      dispatch({
        type: actionTypes.AUTH_SUCCESS,
        username: decoded.username,
        id: decoded.id,
      });
      dispatch(getStudentInfo(decoded.user_id));
      dispatch(
        createMessage({ welcomeBack: `Welcome back ${decoded.username}` })
      );
    }
  } catch (error) {
    dispatch({ type: actionTypes.AUTH_FAIL });
    dispatch(
      createMessage({ authFail: `Please Login or Create A New Account` })
    );
  }
};

export const authSuccess = () => {
  // TODO
  // set user tokens in storage and set user ID / username in state
};

export const authFail = () => {
  // TODO
  // set auth failed
  console.log("dispatching AUTH_FAIL");
  return { type: actionTypes.AUTH_FAIL };
};

export const logout = (ownProps) => async (dispatch) => {
  dispatch({ type: actionTypes.LOGOUT_START });
  try {
    const response = await axiosInstance.post("/blacklist/", {
      refresh_token: localStorage.getItem("refresh_token"),
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    axiosInstance.defaults.headers["Authorization"] = null;
    dispatch({ type: actionTypes.LOGOUT });
    ownProps.history.push("/login");
  } catch (error) {
    // TODO
    console.log(error);
  }
};

export const passwordResetStart = (email, ownProps) => async (dispatch) => {
  dispatch({ type: actionTypes.START_PASSWORD_RESET_REQUEST });
  try {
    let response = await axios.post(`${BASE_URL}dj-rest-auth/password/reset/`, {
      email,
    });
    if (response.status === 200) {
      dispatch({ type: actionTypes.PASSWORD_RESET_REQUEST_SUCCESS });
      dispatch(
        createMessage({
          welcomeBack: `Email sent!`,
        })
      );
    } else {
      dispatch({ type: actionTypes.PASSWORD_RESET_REQUEST_FAIL });
      throw new Error("There was an error");
    }
  } catch (error) {
    dispatch({ type: actionTypes.PASSWORD_RESET_REQUEST_FAIL });
    dispatch(
      createMessage({
        loginFail: `There was a problem.  Please try again.`,
      })
    );
  }
};

export const passwordResetConfirmStart = (
  uid,
  token,
  new_password1,
  new_password2,
  ownProps
) => async (dispatch) => {
  dispatch({ type: actionTypes.START_PASSWORD_RESET_CONFIRM_REQUEST });
  try {
    let response = await axios.post(
      `${BASE_URL}dj-rest-auth/password/reset/confirm/`,
      {
        uid,
        token,
        new_password1,
        new_password2,
      }
    );
    if (response.status === 200) {
      ownProps.history.push("/login/");
      dispatch(
        createMessage({
          welcomeBack: `Password reset successfully!  Please log in.`,
        })
      );
    } else {
      throw new Error("There was an error");
    }
    console.log(response);
  } catch (error) {
    dispatch({ type: actionTypes.PASSWORD_RESET_CONFIRM_REQUEST_FAIL });
    dispatch(
      createMessage({
        loginFail: `There was a problem resetting your password`,
      })
    );
    console.log(error);
  }
};
