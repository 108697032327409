import * as actionTypes from "../actions/actionTypes";
import axiosInstance from "../../axiosAPI";

// CREATE MESSAGE

export const getStudentInfo = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`user/${id}/profile/`);
    dispatch({
      type: actionTypes.GET_STUDENT_INFO,
      firstName: response.data.first_name,
      lastName: response.data.last_name,
      lessonsCompleted: response.data.lessons_completed,
    });
  } catch (error) {
    // TODO handle errors
    console.log(error);
  }
};

export const updateStudentLessonsComplete = (lessonsCompleted) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.UPDATE_STUDENT_LESSONS_COMPLETE,
    lessonsCompleted,
  });
};
