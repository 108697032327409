import { Synth } from "./Synth";

export class MonoSynth extends Synth {
  constructor(audioCtx, defaultOsc, maxPolyphony = 1) {
    super(audioCtx, defaultOsc, maxPolyphony);
    this.isPlaying = false;
    this.maxPolyphony = 1;
    // this.oscArray = [];
  }

  // stopNote(endTime) {
  //   const endSecond = endTime ? endTime : this.audioCtx.currentTime;

  //   if (this.oscArray.length) {
  //     const oldOsc = this.oscArray.shift();
  //     oldOsc.gainNode.gain.setValueAtTime(
  //       oldOsc.gainNode.gain.value,
  //       this.audioCtx.currentTime
  //     );
  //     oldOsc.gainNode.gain.exponentialRampToValueAtTime(
  //       0.01,
  //       endSecond + this.ampEnvelope.release
  //     );
  //     oldOsc.osc.stop(endSecond + this.ampEnvelope.release);
  //     this.isPlaying = false;
  //   }
  // }

  // playNote(freq, startTime) {
  //   const startSecond = startTime ? startTime : this.audioCtx.currentTime;
  //   if (!this.isPlaying) {
  //     const osc = this.createOscillator();
  //     const gainNode = this.createGainNode();
  //     this.oscArray.push({ osc, gainNode });

  //     osc.connect(gainNode);
  //     gainNode.connect(this.audioCtx.destination);

  //     osc.frequency.setValueAtTime(freq, this.audioCtx.currentTime);
  //     gainNode.gain.linearRampToValueAtTime(
  //       0.7,
  //       startSecond + this.ampEnvelope.attack
  //     );

  //     //   osc.onended = () => (this.isPlaying = false);

  //     osc.start(startSecond);
  //     this.isPlaying = true;
  //     return;
  //   }
  // }
}
