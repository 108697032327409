import React from "react";
import styled from "styled-components/macro";

const StyledButton = styled.button`
  padding: 1rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  border: none;
  background-color: ${({ theme: { colors }, clickable, bgCol }) =>
    clickable ? (bgCol ? bgCol : colors.secondary) : colors.muted};
  margin-top: 1rem;
  cursor: ${(props) => (props.clickable ? "pointer" : "not-allowed")};
  letter-spacing: 0.2rem;
  transition: all 0.3s;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);

  :hover {
    letter-spacing: 0.5rem;
    background-color: ${({ theme: { colors }, clickable, bgCol }) =>
      clickable ? (bgCol ? bgCol : colors.secondary) : colors.muted};
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.6);
  }
`;

const Button = (props) => {
  return (
    <StyledButton onClick={props.clickHandler} {...props}>
      {props.children}
    </StyledButton>
  );
};

export default Button;
