import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import axiosAPI from "../../axiosAPI";
import { connect } from "react-redux";
import { updateStudentLessonsComplete } from "../../store/actions/student";
import {
  addKeyPress,
  shiftKeyPressArray,
  setCompletedConditionArray,
  resetKeyPressArray,
  enableKeyInKeyEnabledArray,
  disableKeyInKeyEnabledArray,
  incrementNumCardsCompleted,
  setCardsCompletedToZero,
} from "../../store/actions/lessonStatus";

import LessonCard from "../../components/Lessons/LessonCard";
import Button from "../../components/Button/Button";

// import { ReactAudioContext } from "./AudioContext";
// import { AudioContextProvider } from "../AudioContext";

import SineSynth from "../../components/WebAudio/Synth/SineSynth";
import MultipleChoiceBox from "../MultipleChoiceBox/MultipleChoiceBox";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
`;

const LessonDetailView = (props) => {
  const {
    updateCompletedLessons,
    keyPressArray,
    completedConditionArray,
    removeOldestKeyPress,
    registerKeyPress,
    clearKeyPressArray,
    setCompletedConditionArray,
    keyEnabledArray,
    enableKey,
    disableKey,
    history,
    axiosInstance,
    numCardsCompleted,
    markCardCompleted,
    initNumCardsCompleted,
  } = props;

  const [lessonDetails, setLessonDetails] = useState({
    lesson_number: "",
    description: "",
    cards: {},
    polyphony: {
      polyphony: "M",
    },
    musical_typing: [],
  });
  const [lessonCards, setLessonCards] = useState([]);

  const [currentCard, setCurrentCard] = useState(0);

  // field used to track whether or not student has typed
  // or completed all requirements to move forward
  const [cardCompleted, setCardCompleted] = useState(false);

  const getLessonNumber = useCallback(() => {
    let splitURL = history.location.pathname.split("/");
    let finalIndex = splitURL.length - 1;
    return splitURL[finalIndex];
  }, [history]);

  const keyPressHandler = (e) => {
    if (e.repeat || !keyEnabledArray[e.keyCode]) {
      return;
    }
    disableKey(e.keyCode);

    const keyPressArrayLength = keyPressArray.length;
    const requirementArrayLength = completedConditionArray.length;

    if (keyPressArrayLength < requirementArrayLength) {
      registerKeyPress(e.key);
    } else {
      removeOldestKeyPress();
      registerKeyPress(e.key);
    }
  };

  const keyReleaseHandler = (e) => {
    enableKey(e.keyCode);
  };

  const setCardCompletedConditionArray = useCallback(
    (cardNum) => {
      if (lessonCards[cardNum].student_must_type === "") {
        setCardCompleted(true);
        return;
      }
      const conditionArray = lessonCards[cardNum].student_must_type.split(",");
      setCompletedConditionArray(conditionArray);
    },
    [lessonCards, setCompletedConditionArray]
  );

  const checkCardCompleted = useCallback(() => {
    const joinedCompletedConditionArray = completedConditionArray.join("");
    const joinedKeyPressArray = keyPressArray.join("");
    if (joinedKeyPressArray == joinedCompletedConditionArray) {
      setCardCompleted(true);
    }
  }, [completedConditionArray, keyPressArray, setCardCompleted]);

  // register event listeners to track student input
  useEffect(() => {
    window.addEventListener("keydown", keyPressHandler);
    window.addEventListener("keyup", keyReleaseHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
      window.removeEventListener("keyup", keyReleaseHandler);
    };
  });

  // AJAX call to populate lesson details / cards
  useEffect(() => {
    const lessonNumber = getLessonNumber();
    async function getLessonDetails() {
      try {
        const lessonDetailResponse = await axiosInstance.get(
          `/lessons/${lessonNumber}/`
        );
        console.log("lessonDetailResponse : ", lessonDetailResponse);

        const lessonCardsResponse = await axiosInstance.get(
          `/lessons/${lessonNumber}/cards/`
        );
        console.log("lessonCardsResponse : ", lessonCardsResponse);

        setLessonDetails(lessonDetailResponse.data);
        setLessonCards(lessonCardsResponse.data);
        setCurrentCard(1);
      } catch (error) {
        // TODO handle errors
        console.log(error);
      }
    }
    getLessonDetails();
    initNumCardsCompleted();
  }, []);

  // set the initial completedConditionArray for card 1
  useEffect(() => {
    if (!lessonCards.length) {
      return;
    }
    setCardCompletedConditionArray(0);
  }, [setCardCompletedConditionArray, lessonCards.length]);

  const checkLessonCompleted = async () => {
    if (currentCard == lessonCards.length) {
      // TODO create message that congratulates user on completing lesson

      const lessonNumber = getLessonNumber();
      updateCompletedLessons(lessonNumber);
      // make api call to update student has completed another lesson
      await axiosInstance.patch(
        `/user/${props.userId}/profile/update-progress/`,
        {
          lesson_completed: lessonNumber,
        }
      );
      props.history.push("/lessons");
      // redirect to /lessons
    }
  };

  const nextButtonHandler = (e, card) => {
    e.preventDefault();
    checkLessonCompleted();
    if (currentCard < lessonCards.length) {
      setCurrentCard((prevState) => prevState + 1);
      if (card.number_in_sequence === numCardsCompleted + 1) {
        markCardCompleted();
      }
      clearKeyPressArray();
    }
  };

  const backButtonHandler = (e) => {
    e.preventDefault();
    setCurrentCard((prevState) => prevState - 1);
  };

  // reset card completed conditions after we move to new card
  useEffect(() => {
    if (!currentCard) {
      return;
    }
    // one based indexing in python/Django, but zero based indexing in JS
    const cardNum = currentCard - 1;
    setCardCompleted(false);
    setCardCompletedConditionArray(cardNum);
  }, [setCardCompletedConditionArray, currentCard]);

  // check if cardIsCompleted after every keypress
  useEffect(() => {
    checkCardCompleted();
  }, [keyPressArray, checkCardCompleted]);

  const isNextClickable = (card) => {
    if (cardCompleted) {
      return true;
    } else if (numCardsCompleted >= card.number_in_sequence) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {lessonCards.map((card) => (
        <LessonCard
          data-testid={`lesson-card${card.number_in_sequence}`}
          key={card.number_in_sequence}
          display={card.number_in_sequence === currentCard ? "block" : "none"}
        >
          {card.number_in_sequence === 1 && (
            <h1>Lesson {lessonDetails.lesson_number}</h1>
          )}
          {card.text
            .replace(/\n/g, "&nbsp")
            .split("\r")
            .map((line, index) => (
              // TODO needs stable keys here
              <div
                key={line + index}
                dangerouslySetInnerHTML={{ __html: line }}
              ></div>
            ))}
          {card.multiple_choice !== null ? (
            <MultipleChoiceBox
              question={card.multiple_choice}
              buttonClickHandler={(e) => nextButtonHandler(e, card)}
            />
          ) : null}
          <ButtonRow>
            <Button
              clickHandler={
                card.number_in_sequence > 1 ? backButtonHandler : () => {}
              }
              clickable={card.number_in_sequence > 1}
            >
              BACK
            </Button>
            {card.multiple_choice === null ? (
              <Button
                clickHandler={
                  isNextClickable(card)
                    ? (event) => nextButtonHandler(event, card)
                    : () => {}
                }
                clickable={isNextClickable(card) ? true : false}
              >
                NEXT
              </Button>
            ) : null}
          </ButtonRow>
        </LessonCard>
      ))}
      <SineSynth
        letters={lessonDetails.musical_typing.includes("Letters")}
        numbers={lessonDetails.musical_typing.includes("Numbers")}
        // TODO have to add these properties on the backend database
        oneShotChords={lessonDetails.polyphony.polyphony === "C" ? true : false}
        polyphonic={
          lessonDetails.polyphony.polyphony === "P" ||
          lessonDetails.polyphony.polyphony === "C"
            ? true
            : false
        }
        keyEnabledArray={keyEnabledArray}
      />
    </div>
  );
};

LessonDetailView.defaultProps = {
  axiosInstance: axiosAPI,
};

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  keyPressArray: state.lessonStatus.keyPressArray,
  completedConditionArray: state.lessonStatus.completedConditionArray,
  keyEnabledArray: state.lessonStatus.keyEnabledArray,
  numCardsCompleted: state.lessonStatus.numCardsCompleted,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompletedLessons: (lessonNum) =>
      dispatch(updateStudentLessonsComplete(lessonNum)),

    setCompletedConditionArray: (arr) =>
      dispatch(setCompletedConditionArray(arr)),

    registerKeyPress: (key) => dispatch(addKeyPress(key)),

    removeOldestKeyPress: () => dispatch(shiftKeyPressArray()),

    clearKeyPressArray: () => dispatch(resetKeyPressArray()),

    enableKey: (keyCode) => dispatch(enableKeyInKeyEnabledArray(keyCode)),

    disableKey: (keyCode) => dispatch(disableKeyInKeyEnabledArray(keyCode)),

    markCardCompleted: () => dispatch(incrementNumCardsCompleted()),

    initNumCardsCompleted: () => dispatch(setCardsCompletedToZero()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetailView);
