import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { refreshTokenStart } from "./store/actions/auth";

import LessonList from "./containers/LessonListView/LessonListView";
import LessonDetail from "./containers/LessonDetailView/LessonDetailView";
import UserProfile from "./containers/UserProfileview";

import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import Signup from "./components/Auth/Signup";

import Welcome from "./containers/WelcomePage/WelcomePage";
import PasswordReset from "./components/Auth/PasswordReset";
import PasswordResetConfirm from "./components/Auth/PasswordResetConfirm";

const PASSWORD_RESET_PATH = "/password-reset/";
const PASSWORD_RESET_CONFIRM_PATH = "/api/password-reset/confirm/:uid/:token/";

// returns true for all routes accessible to non-authenticated users
const isRouteAccessible = (path) => {
  if (
    path.includes("password-reset") ||
    path.includes("login") ||
    path.includes("logout")
  ) {
    return true;
  }
  return false;
};

// history.location.pathname.includes("password-reset")

const BaseRouter = (props) => {
  const { refreshToken } = props;
  let history = useHistory();
  useEffect(() => {
    if (!isRouteAccessible(history.location.pathname)) {
      refreshToken();
    }
  }, [refreshToken]);
  return (
    <div>
      <Switch>
        <Route exact path={PASSWORD_RESET_PATH} component={PasswordReset} />
        <Route
          exact
          path={PASSWORD_RESET_CONFIRM_PATH}
          component={PasswordResetConfirm}
        />
        <Route exact path={"/login/"} component={Login} />
        <Route exact path={"/logout/"} component={Logout} />
        <Route exact path={"/signup/"} component={Signup} />
        <Route exact path="/profile" component={UserProfile} />
        <Route exact path="/lessons" component={LessonList} />
        <Route exact path="/lessons/:lessonId" component={LessonDetail} />
        <Route path="/" component={Welcome} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  refreshToken: refreshTokenStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseRouter);
