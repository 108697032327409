import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { lighten } from "polished";

const CustomLink = styled(NavLink)`
  text-decoration: none;
  display: grid;
  align-content: center;
  height: 100%;
  padding: 2rem;

  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
  color: ${({ theme: { colors } }) => colors.muted};

  &.active-link {
    color: ${({ theme: { colors } }) => colors.info};
    border-bottom: 2px solid;
    background-color: ${({ theme: { colors } }) => lighten(0.05, colors.dark)};
  }
`;

const CustomNavLink = (props) => {
  return (
    <CustomLink exact to={props.href} activeClassName="active-link">
      {props.children}
    </CustomLink>
  );
};

export default CustomNavLink;
