// // using an octave higher instead
// export const noteFrequencyDict = {
//   C: 130.81,
//   D: 146.83,
//   E: 164.81,
//   F: 174.61,
//   G: 196.0,
//   A: 220.0,
//   B: 246.94,
//   upperC: 261.63,
// };

export const noteFrequencyDict = {
  lowerB: 246.94,
  C: 261.63,
  D: 293.66,
  E: 329.63,
  F: 349.23,
  G: 392.0,
  A: 440.0,
  B: 493.88,
  upperC: 523.25,
  upperD: 587.33,
  upperE: 659.25,
  upperF: 698.46,
  upperG: 783.99,
};

export const noteFrequencyLetterDict = {
  C: 261.63,
  D: 293.66,
  E: 329.63,
  F: 349.23,
  G: 392.0,
  A: 220.0,
  B: 246.94,
  upperA: 440.0,
};
