import axios from "axios";
import decode from "jwt-decode";
import history from "./history";

let BASE_URL = "https://www.mrsgsmusicsuiteapi.com/api/";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://127.0.0.1:8000/api/";
}

// export const BASE_URL = ;
export { BASE_URL };

// TODO all urls should be defined elsewhere and imported for consolidation
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "Content-type": "application/json",
    accept: "application/json",
  },
});

// if access token is expired, try to renew
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    console.log(error.response);
    console.log(error.response.status);
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === `/token/refresh/`
    ) {
      history.push("/");
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // TODO remove console.log
      console.log("Trying to referesh token");

      // TODO this is not DRY.  This should always be handled in the auth action
      const refreshToken = localStorage.getItem("refresh_token");
      console.log("Refresh Token: " + refreshToken);
      if (!refreshToken) {
        console.log("no refresh token in axiosAPI");
        return history.push("/login");
      }
      return axiosInstance
        .post("/token/refresh/", { refresh: refreshToken })
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            // TODO remove console.log
            console.log("successfully renewed the token");
            const decoded = decode(response.data.access);
            // TODO remove console.log
            console.log(decoded);
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
            axiosInstance.defaults.headers["Authorization"] =
              "Bearer " + response.data.access;
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.access;

            return axiosInstance(originalRequest);
          }
        })
        .catch((err) => {
          // TODO remove console.log
          console.log("there was an error in the interceptor");
          console.log(err);
        });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
