import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;

  font-size: 2rem;
`;

const FormCard = styled.div`
  padding: 3rem 5rem;
  background-color: ${({ theme: { colors } }) => colors.secondary};
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  .form-title {
    margin-bottom: 1rem;
  }

  .subheader {
    font-size: 1.6rem;
    margin: 0 auto;
    margin-top: 3.2rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  .password-reset,
  .signup-link,
  .submit {
    margin-bottom: 1rem;
    font-size: 1.6rem;
    padding: 1rem;
    display: inline-block;
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.primary};
    border: none;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.info};
      color: #000;
      letter-spacing: 0.2rem;
    }
  }

  .password-reset,
  .signup-link {
    margin-left: 2rem;
  }

  .form-extras {
    margin-top: 2rem;
    font-size: 1.6rem;
    font-weight: 500;
  }

  .form-group {
    position: relative;
  }

  .form-group-input {
  }
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .input-field {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    padding: 1rem;
    border-radius: 10px;
  }
`;

// expects props.fields
// example: [{ name: "username", type: "text", label: "Username" }, { name: "password": type: "password", label: "Password"}]

// expects props.formName
// example: "Login"

// expects submitHandler
// example: const handleSubmit = (event, formState) => {
//     console.log(event)
//     console.log(formState)
// }

const Form = (props) => {
  const [formState, setFormState] = useState({});

  const handleChange = (event) => {
    console.log(event.target.name);
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <Container>
      <FormCard>
        <h2 className="form-title">{props.formName}</h2>
        {props.subHeader && <p className="subheader">{props.subHeader}</p>}
        <StyledForm onSubmit={(event) => props.submitHandler(event, formState)}>
          <div className="form-group">
            {props.fields.map((field) => (
              <StyledLabel key={field.name}>
                {/* {field.label} */}
                <input
                  className="input-field form-group-input"
                  type={field.type}
                  name={field.name}
                  placeholder={
                    field.placeholder ? field.placeholder : field.name
                  }
                  onChange={handleChange}
                />
              </StyledLabel>
            ))}
          </div>
          <input
            className="submit"
            type="submit"
            value={props.submitLabel ? props.submitLabel : props.formName}
          />
          <div className="form-extras">
            {props.includeSignup && (
              <div>
                <p>
                  Don't have an account yet?{" "}
                  <Link className="signup-link" to="/signup/">
                    Sign up
                  </Link>{" "}
                </p>
              </div>
            )}
            {props.includePasswordReset && (
              <div>
                <p>
                  Forgot Password?
                  <Link to="/password-reset/" className="password-reset">
                    Reset Password
                  </Link>
                </p>
              </div>
            )}
          </div>
        </StyledForm>
      </FormCard>
    </Container>
  );
};

export default Form;
