import * as actionTypes from "../actions/actionTypes";

const initialState = {
  firstName: "",
  lastName: "",
  lessonsCompleted: 0,
  currentLesson: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_INFO:
      const currentLesson = action.lessonsCompleted + 1;
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        lessonsCompleted: action.lessonsCompleted,
        currentLesson,
      };
    case actionTypes.UPDATE_STUDENT_LESSONS_COMPLETE:
      if (Number(action.lessonsCompleted) > Number(state.lessonsCompleted)) {
        return {
          ...state,
          lessonsCompleted: Number(action.lessonsCompleted),
          currentLesson: Number(action.lessonsCompleted) + 1,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
