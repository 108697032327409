import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import BaseRouter from "./routes";
import Theme from "./components/Theme/Theme";
import CustomLayout from "./containers/Layout";
import GlobalStyle from "./components/Theme/globalStyle";
import history from "./history";
import { useMediaQuery } from "react-responsive";

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { refreshTokenStart } from "./store/actions/auth";
import { AudioContextProvider } from "./containers/AudioContext";
import UseDesktopAlert from "./components/MediaQueryAlert/UseDesktopAlert";

// Alert Options
const alertOptions = {
  timeout: 3000, //ms
  position: "top center",
  // offset: "9rem",
};

function App(props) {
  const is1100pxWide = useMediaQuery({
    query: "(min-width: 1100px)",
  });

  // const { refreshToken } = props;
  // useEffect(() => {
  //   refreshToken();
  // }, [refreshToken]);

  return (
    <div className="App">
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <AudioContextProvider>
          <Theme>
            {!is1100pxWide ? (
              <UseDesktopAlert />
            ) : (
              <Router history={history}>
                <CustomLayout>
                  <BaseRouter />
                </CustomLayout>
              </Router>
            )}
          </Theme>
        </AudioContextProvider>
      </AlertProvider>
      <GlobalStyle />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  // refreshToken: refreshTokenStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
