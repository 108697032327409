import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";

import FormHolder from "../Form/FormHolder";
import Form from "../Form/Form";
import Spinner from "../UI/Spinner";

import { passwordResetStart } from "../../store/actions/auth";

const Container = styled.div`
  font-size: 2.2rem;
`;

const SuccessMessageHolder = styled.div`
  background-color: ${({ theme: { colors } }) => colors.secondary};
  margin: 0 auto;
  margin-top: 5rem;
  max-width: 70%;
  border-radius: 20px;
  font-size: 2.4rem;
  padding: 4.4rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
`;

const PasswordReset = (props) => {
  const {
    passwordResetStarted,
    startResetRequest,
    passwordResetSuccess,
  } = props;

  const handleSubmit = async (event, formState) => {
    event.preventDefault();
    startResetRequest(formState.email);
  };

  const fields = [
    {
      name: "email",
      type: "email",
      label: "email",
    },
  ];
  const formName = "Reset Password";
  const subHeader =
    "Enter the email you used to register and we will email you a link to reset your password.";
  let form;
  if (!passwordResetStarted && !passwordResetSuccess) {
    form = (
      <Form
        fields={fields}
        formName={formName}
        subHeader={subHeader}
        submitHandler={handleSubmit}
      />
    );
  } else if (passwordResetStarted) {
    form = <Spinner />;
  } else {
    form = (
      <SuccessMessageHolder>
        <p>Please check your email to reset your password.</p>
        <p>(If you don't see a message, try checking your spam folder.)</p>
      </SuccessMessageHolder>
    );
  }
  return (
    <Container>
      <FormHolder>{form}</FormHolder>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  passwordResetStarted: state.auth.passwordResetStarted,
  passwordResetSuccess: state.auth.passwordResetSuccess,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startResetRequest: (email) => dispatch(passwordResetStart(email, ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
