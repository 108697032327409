import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";

import Spinner from "../UI/Spinner";

const Logout = (props) => {
  const { logout } = props;
  useEffect(() => {
    logout();
  }, [logout]);
  return <Spinner />;
};

const mapStateToProps = (state) => ({
  logoutStarted: state.auth.logoutStarted,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => dispatch(logout(ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
