export class Synth {
  constructor(audioCtx, defaultOsc, maxPolyphony = 32) {
    this.audioCtx = audioCtx;
    this.defaultOsc = defaultOsc; // "sine, sawtooth, square, triangle, etc...""
    this.maxPolyphony = maxPolyphony;
    this.compressorNode = this.createOutputCompressor(audioCtx);
    this.currentPolyphony = 0;
    this.oscArray = [];
    this.freqArray = [];
    this.ampEnvelope = {
      attack: 0.02,
      decay: 0.2,
      sustain: 0.8, // this value in range [0, 1]
      release: 0.4,
    };
  }

  createOutputCompressor(audioCtx) {
    if (!audioCtx) {
      return;
    }
    // create compressor node
    const compressor = audioCtx.createDynamicsCompressor();
    compressor.threshold.setValueAtTime(-15, audioCtx.currentTime);
    compressor.knee.setValueAtTime(0, audioCtx.currentTime);
    compressor.ratio.setValueAtTime(10, audioCtx.currentTime);
    compressor.attack.setValueAtTime(0, audioCtx.currentTime);
    compressor.release.setValueAtTime(0.25, audioCtx.currentTime);

    // connect the AudioBufferSourceNode to the destination
    compressor.connect(audioCtx.destination);
    return compressor;
  }

  createOscillator(oscType = this.defaultOsc) {
    const oscillator = this.audioCtx.createOscillator();
    oscillator.type = oscType;
    return oscillator;
  }

  createGainNode() {
    const gainNode = this.audioCtx.createGain();
    gainNode.gain.value = 0;
    return gainNode;
  }

  removeOscFromOscArray(index) {
    const updatedOscArray = [...this.oscArray];
    const oldOsc = updatedOscArray.splice(index, 1)[0];
    this.oscArray = updatedOscArray;
    return oldOsc;
  }

  removeFrequencyFromFreqArray(freq) {
    const updatedFreqArray = [...this.freqArray];
    const freqIndex = updatedFreqArray.findIndex((oldFreq) => oldFreq == freq);
    updatedFreqArray.splice(freqIndex, 1);
    this.freqArray = updatedFreqArray;
  }

  stopOldestNote() {
    const oldOsc = this.removeOscFromOscArray(0);
    this.removeFrequencyFromFreqArray(this.freqArray[0]);
    this.stopOscillator(oldOsc, this.audioCtx.currentTime);
  }

  stopOscillator(oldOsc, endSecond) {
    oldOsc.gainNode.gain.setValueAtTime(
      oldOsc.gainNode.gain.value,
      this.audioCtx.currentTime
    );
    oldOsc.gainNode.gain.exponentialRampToValueAtTime(
      0.00001,
      endSecond + this.ampEnvelope.release
    );
    oldOsc.osc.stop(endSecond + this.ampEnvelope.release);
    this.currentPolyphony = this.currentPolyphony - 1;
  }

  stopNote(freq, endTime) {
    const endSecond = endTime ? endTime : this.audioCtx.currentTime;
    if (!freq) {
      this.stopOldestNote();
    }

    if (this.oscArray.length) {
      const oldOscIndex = this.oscArray.findIndex((osc) => osc.freq == freq);
      if (oldOscIndex == -1) {
        return;
      }
      const oldOsc = this.removeOscFromOscArray(oldOscIndex);
      this.removeFrequencyFromFreqArray(freq);
      this.stopOscillator(oldOsc, endSecond);
    }
  }

  playNote(freq, startTime) {
    if (this.freqArray.includes(freq)) {
      return;
    }
    const startSecond = startTime ? startTime : this.audioCtx.currentTime;
    if (this.currentPolyphony < this.maxPolyphony) {
      const osc = this.createOscillator();
      const gainNode = this.createGainNode();
      this.oscArray.push({ osc, freq, gainNode });
      this.freqArray.push(freq);

      osc.connect(gainNode);
      gainNode.connect(this.compressorNode);

      osc.frequency.setValueAtTime(freq, this.audioCtx.currentTime);
      gainNode.gain.linearRampToValueAtTime(
        0.1,
        startSecond + this.ampEnvelope.attack
      );

      osc.start(startSecond);
      this.currentPolyphony = this.currentPolyphony + 1;
      return;
    }
  }
}
