import React, { useEffect, useContext, useCallback } from "react";
import { ReactAudioContext } from "../../../containers/AudioContext";
import { MonoSynth } from "../../../Instruments/Synth/MonoSynth";
import { PolySynth } from "../../../Instruments/Synth/PolySynth";
import {
  getNoteFrequencyNumberKeys,
  getNoteFrequencyLetterKeys,
  getThird,
  getFifth,
} from "../../../Util/Audio/getNoteFrequency";
// import PropTypes from "prop-types";

const SineSynth = (props) => {
  const audioCtx = useContext(ReactAudioContext);
  const {
    letters,
    numbers,
    oneShotChords,
    keyEnabledArray,
    polyphonic,
  } = props;

  const synth = polyphonic
    ? new PolySynth(audioCtx, "sine")
    : new MonoSynth(audioCtx, "sine");

  const getNoteFrequency = useCallback(
    (e) => {
      // top row of keys on keyboard
      if (
        numbers &&
        (e.code.startsWith("Digit") ||
          e.code.startsWith("Minus") ||
          e.code.startsWith("Equal") ||
          e.code.startsWith("Backquote"))
      ) {
        return getNoteFrequencyNumberKeys(e);
      }
      if (letters && e.code.startsWith("Key")) {
        return getNoteFrequencyLetterKeys(e);
      }
    },
    [letters, numbers]
  );

  const playNote = useCallback(
    (e) => {
      // ensures we don't get rapid repeated notes
      // if key is simply held down
      if (e.repeat || !keyEnabledArray[e.keyCode]) {
        return;
      }

      const frequency = getNoteFrequency(e);
      if (frequency !== undefined && frequency !== null) {
        if (synth.currentPolyphony >= synth.maxPolyphony) {
          synth.stopNote();
          synth.playNote(frequency);
          return;
        }
        if (synth.currentPolyphony < synth.maxPolyphony) {
          synth.playNote(frequency);
        }
      }
    },
    [synth, keyEnabledArray, getNoteFrequency]
  );

  const stopNote = useCallback(
    (e) => {
      const frequency = getNoteFrequency(e);
      if (synth.currentPolyphony > 0) {
        synth.stopNote(frequency);
      }
    },
    [synth, getNoteFrequency]
  );

  const playChord = useCallback(
    (e) => {
      e.stopPropagation();
      if (e.repeat) {
        return;
      }
      const third = getThird(e);
      const fifth = getFifth(e);
      playNote(e);
      playNote({ ...third, repeat: false, keyCode: e.keyCode });
      playNote({ ...fifth, repeat: false, keyCode: e.keyCode });
    },
    [playNote]
  );

  const stopChord = useCallback(
    (e) => {
      e.stopPropagation();
      const third = getThird(e);
      const fifth = getFifth(e);
      if (synth.currentPolyphony > 0) {
        stopNote(e);
        stopNote(third);
        stopNote(fifth);
      }
    },
    [stopNote, synth.currentPolyphony]
  );

  useEffect(() => {
    window.addEventListener("keydown", oneShotChords ? playChord : playNote);
    window.addEventListener("keyup", oneShotChords ? stopChord : stopNote);

    return () => {
      window.removeEventListener(
        "keydown",
        oneShotChords ? playChord : playNote
      );
      window.removeEventListener("keyup", oneShotChords ? stopChord : stopNote);
    };
  }, [
    letters,
    numbers,
    oneShotChords,
    playChord,
    stopChord,
    playNote,
    stopNote,
    polyphonic,
  ]);
  return <></>;
};

// SineSynth.propTypes = {
//   // letters & numbers determines how the musical typing
//   // will be handled (letters for A - G, numbers 1 - 8)
//   letters: PropTypes.bool.isRequired,
//   numbers: PropTypes.bool.isRequired,
// };

export default React.memo(SineSynth);
