import React from "react";
import styled from "styled-components/macro";
import Form from "../Form/Form";
import FormHolder from "../Form/FormHolder";

import { connect } from "react-redux";
import { passwordResetConfirmStart } from "../../store/actions/auth";

const Container = styled.div`
  font-size: 2.2rem;
`;

const PasswordResetConfirm = (props) => {
  const { history, startPasswordConfirmRequest } = props;
  const handleSubmit = async (event, formState) => {
    event.preventDefault();
    const splitHistory = history.location.pathname.split("/");
    const uidIndex = splitHistory.length - 3;
    const tokenIndex = splitHistory.length - 2;
    const uid = splitHistory[uidIndex];
    const token = splitHistory[tokenIndex];
    startPasswordConfirmRequest(
      uid,
      token,
      formState.new_password1,
      formState.new_password2
    );
  };
  const fields = [
    {
      name: "new_password1",
      type: "password",
      label: "new password",
      placeholder: "Enter new password",
    },
    {
      name: "new_password2",
      type: "password",
      label: "repeat new password",
      placeholder: "Re-enter new password",
    },
  ];
  const formName = "Choose your new password";
  let form;
  form = (
    <Form
      fields={fields}
      formName={formName}
      submitHandler={handleSubmit}
      submitLabel="Reset password"
    />
  );
  return (
    <Container>
      <FormHolder>{form}</FormHolder>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startPasswordConfirmRequest: (uid, token, new_password1, new_password2) =>
      dispatch(
        passwordResetConfirmStart(
          uid,
          token,
          new_password1,
          new_password2,
          ownProps
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetConfirm);
