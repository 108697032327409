import React from "react";
import { connect } from "react-redux";
import Form from "../Form/Form";
import FormHolder from "../Form/FormHolder";
// import styled from "styled-components/macro";

import { signupStart } from "../../store/actions/auth";
import Spinner from "../UI/Spinner";

const Signup = (props) => {
  const { startSignup, signupStart } = props;
  const handleSubmit = async (event, formState) => {
    event.preventDefault();
    console.log("signing up");
    startSignup(
      formState.username,
      formState.password,
      formState.email
      // formState.classCode
    );
  };

  const fields = [
    { name: "username", type: "text", label: "Username" },
    { name: "password", type: "password", label: "Password" },
    { name: "email", type: "email", label: "E-mail" },
    // { name: "class-code", type: "text", label: "class code" },
  ];
  const formName = "Sign Up";

  let form;
  if (signupStart) {
    form = <Spinner />;
  } else {
    form = (
      <Form fields={fields} formName={formName} submitHandler={handleSubmit} />
    );
  }
  return <FormHolder>{form}</FormHolder>;
};

const mapStateToProps = (state) => ({
  signupStart: state.auth.signupStarted,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startSignup: (username, password, email) =>
      dispatch(signupStart(username, password, email, ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
