import React from "react";
import styled from "styled-components/macro";

// Each lesson will be similar to a "slideshow"
// but will instead each be a sequence of "cards"

const Card = styled.div`
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.2);
  font-size: 1.8rem;
  line-height: 2;
  padding: 3.4rem 2.4rem;
  text-align: center;
  width: 70%;
  margin: 2.4rem auto;
  display: ${(props) => props.display};
  h1 {
    margin-bottom: 1rem;
  }
`;

const LessonCard = (props) => {
  return <Card display={props.display}>{props.children}</Card>;
};

export default LessonCard;
