import React, { useEffect } from "react";
import { withAlert, useAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Alerts = (props) => {
  const { message } = props;
  const alert = useAlert();
  //   useEffect(() => {
  //     console.log("effect");
  //     if (props.error.status !== null) {
  //       const fields = [...Object.keys(error.msg)];
  //       for (const field of fields) {
  //         alert.error(`${field}:  ${error.msg[field]}`);
  //       }
  //     }
  //   }, [error]);

  useEffect(() => {
    if (message.welcomeBack) {
      alert.success(`${message.welcomeBack}`);
    }
    if (message.authFail) {
      alert.info(`${message.authFail}`);
    }
    if (message.loginFail) {
      alert.error(`${message.loginFail}`);
    }
  }, [message, alert]);

  return <></>;
};

Alerts.propTypes = {
  //   error: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  //   error: state.errors,
  message: state.messages,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert()(Alerts));
