import React from "react";
import styled from "styled-components/macro";

const FormDiv = styled.div`
  position: relative;
  padding: 6rem;
`;

const FormHolder = (props) => <FormDiv>{props.children}</FormDiv>;

export default FormHolder;
