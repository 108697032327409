import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { hexToRGBA } from "../../Util/Colors/convertColorFormat";

import Button from "../../components/Button/Button";

const Container = styled.div`
  position: relative;
`;

const QuestionTextBox = styled.div`
  margin: 3rem;
  margin-bottom: 0.3rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3rem;
  padding-bottom: 0;

  ul {
    margin-top: 2rem;
    list-style: none;

    li {
      display: block;
      padding: 1rem;
      border: 2px solid ${({ theme: { colors } }) => colors.secondarySquare};
      margin-bottom: 1rem;
      border-radius: 1rem;
      transition: all 0.3s;

      :hover {
        background-color: ${({ theme: { colors } }) =>
          hexToRGBA(colors.secondarySquare, 0.4)};
        cursor: pointer;
      }
    }

    .selected {
      background-color: ${({ theme: { colors } }) =>
        hexToRGBA(colors.secondarySquare, 0.8)};
      font-weight: 700;
      letter-spacing: 0.5rem;
    }
  }
`;

const CorrectAnswerNotification = styled.div`
  position: absolute;
  padding: 8rem;
  z-index: ${({ correctAnswer }) => (correctAnswer ? 10 : -10)};
  opacity: ${({ correctAnswer }) => (correctAnswer ? 1 : 0)};
  font-size: 4.4rem;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: ${({ correctAnswer }) =>
    correctAnswer ? "translate(-50%, -50%)" : "translate(-50%, 0)"};
  color: #fff;
  background-color: ${({ theme: { colors } }) => hexToRGBA(colors.dark, 1)};
  transition: all 0.4s;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.2);
`;

const SubmitDiv = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.secondary};
  transition: all 0.3s;
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem;
  color: #fff;

  :hover {
    background-color: ${({ theme: { colors } }) => colors.secondarySquare};
    cursor: pointer;
    color: #000;
  }
`;

const TryAgainDiv = styled.div`
  font-weight: 700;
  font-size: 2rem;
  visibility: ${({ answerSubmitted, answerCorrect }) =>
    answerSubmitted && !answerCorrect ? "visible" : "hidden"};
`;

const MultipleChoiceBox = (props) => {
  const [selectedAnswer, setSelectedAnswer] = useState({ answer_text: null });
  const [questionAnsweredCorrectly, setQuestionAnsweredCorrectly] = useState(
    false
  );
  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  const checkAnswerHandler = () => {
    setAnswerSubmitted(true);
    if (selectedAnswer.is_correct) {
      setQuestionAnsweredCorrectly(true);
    }
  };

  const { question, buttonClickHandler } = props;
  return (
    <Container>
      <QuestionTextBox>
        {question.question_text}

        <ul>
          {question.multiplechoiceanswer_set.map((ans) => (
            <li
              key={ans.answer_text}
              className={
                selectedAnswer.answer_text === ans.answer_text ? "selected" : ""
              }
              onClick={() => {
                setAnswerSubmitted(false);
                setSelectedAnswer(ans);
              }}
            >
              {ans.answer_text}
            </li>
          ))}
        </ul>
      </QuestionTextBox>
      <TryAgainDiv
        answerSubmitted={answerSubmitted}
        answerCorrect={questionAnsweredCorrectly}
      >
        {selectedAnswer.answer_text === null
          ? "Please select an answer"
          : "Try Again..."}
      </TryAgainDiv>
      <SubmitDiv onClick={checkAnswerHandler}>Check Answer</SubmitDiv>
      <CorrectAnswerNotification correctAnswer={questionAnsweredCorrectly}>
        <div>You got it!</div>
        <Button clickHandler={buttonClickHandler} clickable={true}>
          NEXT
        </Button>
      </CorrectAnswerNotification>
    </Container>
  );
};

export default MultipleChoiceBox;
