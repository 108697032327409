import * as actionTypes from "../actions/actionTypes";

export const addKeyPress = (key) => {
  return {
    type: actionTypes.ADD_KEY_PRESS,
    payload: key,
  };
};

export const setCompletedConditionArray = (conditions) => {
  return {
    type: actionTypes.SET_COMPLETED_CONDITION_ARRAY,
    payload: conditions,
  };
};

// REMOVES THE OLDEST PRESSED KEY FROM THE KEYPRESS ARRAY
export const shiftKeyPressArray = () => {
  return {
    type: actionTypes.SHIFT_KEY_PRESS_ARRAY,
  };
};

export const resetKeyPressArray = () => {
  return {
    type: actionTypes.RESET_KEY_PRESS_ARRAY,
  };
};

export const enableKeyInKeyEnabledArray = (keyCode) => {
  return {
    type: actionTypes.ENABLE_KEY_IN_KEY_ENABLED_ARRAY,
    keyCode,
  };
};

export const disableKeyInKeyEnabledArray = (keyCode) => {
  return {
    type: actionTypes.DISABLE_KEY_IN_KEY_ENABLED_ARRAY,
    keyCode,
  };
};

export const incrementNumCardsCompleted = () => {
  return {
    type: actionTypes.INCREMENT_NUM_CARDS_COMPLETED,
  };
};

export const setCardsCompletedToZero = () => {
  return {
    type: actionTypes.SET_CARDS_COMPLETED_TO_ZERO,
  };
};
