import React, { useEffect, useState } from "react";
import axiosAPI from "../../axiosAPI";
import styled from "styled-components/macro";
import { connect } from "react-redux";

const Container = styled.div`
  width: 80%;
  margin: 0 auto;

  ul {
    list-style: none;
    margin-top: 4rem;
    /* box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
  }

  li {
    font-size: 1.6rem;
    padding: 2rem;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    transition: all 0.3s;

    :first-child {
    }

    :last-child {
    }
  }

  .accessible {
    border-top: 2px solid ${({ theme: { colors } }) => colors.secondary};
    &:hover {
      background-color: ${({ theme: { colors } }) => colors.secondary};
      cursor: pointer;
      color: #eee;
      font-weight: 700;
    }
  }

  .inaccessible {
    background-color: ${({ theme: { colors } }) => colors.muted};
  }

  .published {
  }

  .hidden {
    display: none;
  }
  .staged {
    filter: brightness(50%) blur(6px);
  }
`;

const LessonListView = (props) => {
  const [lessonList, setLessonList] = useState([]);
  const { lessonsCompleted, currentLesson, axiosInstance } = props;

  useEffect(() => {
    // TODO fetch lesson list from LessonListView
    async function fetchFunction() {
      try {
        // const response = await axiosInstance.get("/lessons/");
        const response = await axiosInstance.get("/lessons/");
        const lessons = response.data;
        setLessonList(lessons);
      } catch (error) {}
    }
    fetchFunction();
  }, []);

  const redirectToLessonDetailView = (lessonNumber) => {
    props.history.push(`/lessons/${lessonNumber}`);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center", fontSize: "3rem", marginTop: "3rem" }}>
        Lessons
      </h1>
      <Container>
        <ul>
          {lessonList.map((lesson) => (
            <li
              key={lesson.lesson_number}
              className={
                `${
                  lesson.lesson_number <= currentLesson &&
                  lesson.published_status == "P"
                    ? "accessible"
                    : "inaccessible"
                } ` +
                `${
                  lesson.published_status == "P"
                    ? "published"
                    : lesson.published_status == "S"
                    ? "staged"
                    : "hidden"
                }`
              }
              onClick={() =>
                lesson.lesson_number <= currentLesson &&
                lesson.published_status == "P"
                  ? redirectToLessonDetailView(lesson.id)
                  : () => {}
              }
            >
              {lesson.description}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

LessonListView.defaultProps = {
  axiosInstance: axiosAPI,
};

const mapStateToProps = (state) => ({
  lessonsCompleted: state.student.lessonsCompleted,
  currentLesson: state.student.currentLesson,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LessonListView);
