import React from "react";
import styled from "styled-components/macro";
import NavLink from "./NavLink";

const LinkList = styled.ul`
  list-style-type: none;
  display: flex;

  .spacer {
    margin-left: auto;
  }

  li {
    /* display: grid; */
    /* align-content: center; */
    /* height: 100%; */
    transition: transform 0.6s;

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.primary};
      /* transform: translateY(0.5rem); */
    }
  }
`;

const NavList = (props) => {
  return (
    <LinkList>
      {/* props.links is an array of objects: [ {text: 'Link text', href: '/api/etc...'} ] */}
      {props.links.map((linkObj) => (
        <li key={`${linkObj.href} : ${linkObj.text}`}>
          <NavLink href={linkObj.href}>{linkObj.text}</NavLink>
        </li>
      ))}
    </LinkList>
  );
};

export default NavList;
