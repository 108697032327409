import React from "react";
import styled from "styled-components/macro";

const Logo = styled.a`
  display: grid;
  align-content: center;
  padding: 1rem 2rem;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  color: #dddddd;
  font-weight: 400;
`;

const Brand = (props) => {
  return <Logo>{props.children}</Logo>;
};

export default Brand;
