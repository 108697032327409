import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
      @import url('https://fonts.googleapis.com/css?family=Montserrat|Roboto');

    * {
        margin: 0;
        padding: 0;
    }
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
    html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

    /* @media only screen and (max-width: $bp-large) {
        font-size: 50%;
    } */
}
    body {
        padding: 0;
        margin: 0;
        font-family: Roboto, sans-serif;
    }
    h1 {
        font-family: Montserrat, sans-serif;
        color: cyan;
    }
`;

export default GlobalStyle;
