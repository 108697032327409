import { combineReducers } from "redux";
import auth from "../reducers/auth"; // auth reducer
import messages from "../reducers/messages";
import student from "../reducers/student";
import audio from "../reducers/audio";
import lessonStatus from "./lessonStatus";

export default combineReducers({
  audio,
  auth,
  lessonStatus,
  messages,
  student,
});
