import React from "react";

import Navheader from "../components/Nav/Navheader";
import Alerts from "../components/Alerts/Alerts";

const CustomLayout = (props) => {
  return (
    <main>
      <div>
        <Navheader />
        <Alerts />
        {props.children}
      </div>
    </main>
  );
};

export default CustomLayout;
