import React from "react";
import styled from "styled-components/macro";

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
`;

const Card = styled.div`
  padding: 4rem;
  font-size: 2.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);

  div {
    margin-bottom: 2rem;
  }
`;

const UseDesktopAlert = (props) => {
  return (
    <Backdrop>
      <Card>
        <div>This app will be mobile ready soon!</div>
        <div>
          For now, please use a desktop or laptop and Chrome or Firefox.
        </div>
        <div>
          (If you're already on a computer, try maximizing your browser.)
        </div>
      </Card>
    </Backdrop>
  );
};

export default UseDesktopAlert;
