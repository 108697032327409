import * as actionTypes from "../actions/actionTypes";

const initialState = {
  username: "",
  id: "",
  isAuthenticated: false,
  authFailed: false, // when true, will display error on page
  loginStarted: false, // used to display spinner while logging in
  signupStarted: false,
  passwordResetStarted: false,
  passwordResetSuccess: false,
  passwordResetConfirmFailed: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_START:
      return {
        ...state,
        signupStarted: true,
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        username: action.username,
        id: action.id,
        isAuthenticated: true,
        signupStarted: false,
      };
    case actionTypes.SIGNUP_FAIL:
      return {
        ...state,
        signupStarted: false,
      };
    case actionTypes.LOGIN_START:
      return {
        ...state,
        loginStarted: true,
      };
    case actionTypes.REFRESH_TOKEN_START:
      // TODO
      break;
    case actionTypes.AUTH_SUCCESS:
      // TODO
      return {
        username: action.username,
        id: action.id,
        isAuthenticated: true,
        authFailed: false,
        loginStart: false,
      };
    case actionTypes.AUTH_FAIL:
      // TODO
      return {
        ...state,
        authFailed: true,
        loginStarted: false,
      };
    case actionTypes.LOGOUT_START:
      return {
        ...state,
        logoutStarted: true,
      };
    case actionTypes.LOGOUT:
      return {
        username: "",
        id: "",
        isAuthenticated: false,
        authFailed: false, // when true, will display error on page
        loginStart: false,
        logoutStarted: false,
      };
    case actionTypes.START_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        passwordResetStarted: true,
      };
    case actionTypes.PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        passwordResetStarted: false,
        passwordResetSuccess: true,
      };
    case actionTypes.PASSWORD_RESET_REQUEST_FAIL:
      return {
        ...state,
        passwordResetStarted: false,
        passwordResetSuccess: false,
        passwordResetConfirmFailed: true,
      };
    default:
      return state;
  }
};

export default reducer;
