export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const LOGIN_START = "LOGIN_START";
export const LOGOUT = "LOGOUT";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REFRESH_TOKEN_START = "REFRESH_TOKEN_START";
export const START_PASSWORD_RESET_REQUEST = "START_PASSWORD_RESET_REQUEST";
export const START_PASSWORD_RESET_CONFIRM_REQUEST =
  "START_PASSWORD_RESET_CONFIRM_REQUEST";
export const PASSWORD_RESET_REQUEST_SUCCESS = "PASSWORD_RESET_REQUEST_SUCCESS";
export const PASSWORD_RESET_REQUEST_FAIL = "PASSWORD_RESET_REQUEST_FAIL";
export const PASSWORD_RESET_CONFIRM_REQUEST_SUCCESS =
  "PASSWORD_RESET_CONFIRM_REQUEST_SUCCESS";
export const PASSWORD_RESET_CONFIRM_REQUEST_FAIL =
  "PASSWORD_RESET_CONFIRM_REQUEST_FAIL";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const GET_STUDENT_INFO = "GET_STUDENT_INFO";
export const UPDATE_STUDENT_LESSONS_COMPLETE =
  "UPDATE_STUDENT_LESSONS_COMPLETE";

export const CREATE_AUDIO_CONTEXT = "CREATE_AUDIO_CONTEXT";

export const ADD_KEY_PRESS = "ADD_KEY_PRESS";
export const RESET_KEY_PRESS_ARRAY = "RESET_KEY_PRESS_ARRAY";
export const SET_COMPLETED_CONDITION_ARRAY = "SET_COMPLETED_CONDITION_ARRAY";
export const INCREMENT_NUM_CARDS_COMPLETED = "INCREMENT_NUM_CARDS_COMPLETED";
export const SET_CARDS_COMPLETED_TO_ZERO = "SET_CARDS_COMPLETED_TO_ZERO";

export const SHIFT_KEY_PRESS_ARRAY = "SHIFT_KEY_PRESS_ARRAY";
export const ENABLE_KEY_IN_KEY_ENABLED_ARRAY =
  "ENABLE_KEY_IN_KEY_ENABLED_ARRAY";
export const DISABLE_KEY_IN_KEY_ENABLED_ARRAY =
  "DISABLE_KEY_IN_KEY_ENABLED_ARRAY";
