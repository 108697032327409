import { noteFrequencyDict, noteFrequencyLetterDict } from "./noteFequencyDict";
export const getNoteFrequencyNumberKeys = (event) => {
  switch (event.code) {
    case "Backquote":
      return noteFrequencyDict.lowerB;
    case "Digit1":
      return noteFrequencyDict.C;
    case "Digit2":
      return noteFrequencyDict.D;
    case "Digit3":
      return noteFrequencyDict.E;
    case "Digit4":
      return noteFrequencyDict.F;
    case "Digit5":
      return noteFrequencyDict.G;
    case "Digit6":
      return noteFrequencyDict.A;
    case "Digit7":
      return noteFrequencyDict.B;
    case "Digit8":
      return noteFrequencyDict.upperC;
    case "Digit9":
      return noteFrequencyDict.upperD;
    case "Digit0":
      return noteFrequencyDict.upperE;
    case "Minus":
      return noteFrequencyDict.upperF;
    case "Equal":
      return noteFrequencyDict.upperG;
    default:
      return null;
  }
};

export const getNoteFrequencyLetterKeys = (event) => {
  switch (event.code) {
    case "KeyA":
      return noteFrequencyLetterDict.A;
    case "KeyB":
      return noteFrequencyLetterDict.B;
    case "KeyC":
      return noteFrequencyLetterDict.C;
    case "KeyD":
      return noteFrequencyLetterDict.D;
    case "KeyE":
      return noteFrequencyLetterDict.E;
    case "KeyF":
      return noteFrequencyLetterDict.F;
    case "KeyG":
      return noteFrequencyLetterDict.G;
    default:
      return null;
  }
};

export const getThird = (event) => {
  switch (event.code) {
    case "Backquote":
      return { code: "Digit2" };
    case "Digit1":
      return { code: "Digit3" };
    case "Digit2":
      return { code: "Digit4" };
    case "Digit3":
      return { code: "Digit5" };
    case "Digit4":
      return { code: "Digit6" };
    case "Digit5":
      return { code: "Digit7" };
    case "Digit6":
      return { code: "Digit8" };
    case "Digit7":
      return { code: "Digit9" };
    case "Digit8":
      return { code: "Digit0" };
    case "Digit9":
      return { code: "Minus" };
    case "Digit0":
      return { code: "Equal" };
    case "Minus":
      return { code: "null" };
    case "Equal":
      return { code: "null" };
    case "KeyA":
      return { code: "KeyC" };
    case "KeyB":
      return { code: "KeyD" };
    case "KeyC":
      return { code: "KeyE" };
    case "KeyD":
      return { code: "KeyF" };
    case "KeyE":
      return { code: "KeyG" };
    case "KeyF":
      return { code: "KeyA" };
    case "KeyG":
      return { code: "KeyB" };
    default:
      return { code: "null" };
  }
};

export const getFifth = (event) => {
  switch (event.code) {
    case "Backquote":
      return { code: "Digit4" };
    case "Digit1":
      return { code: "Digit5" };
    case "Digit2":
      return { code: "Digit6" };
    case "Digit3":
      return { code: "Digit7" };
    case "Digit4":
      return { code: "Digit8" };
    case "Digit5":
      return { code: "Digit9" };
    case "Digit6":
      return { code: "Digit0" };
    case "Digit7":
      return { code: "Minus" };
    case "Digit8":
      return { code: "Equal" };
    case "Digit9":
      return { code: "null" };
    //   return ({ code: "Minus" });
    case "Digit0":
      return { code: "null" };
    //   return ({ code: "Equal" });
    case "Minus":
      return { code: "null" };
    //   return ({ code: "null" });
    case "Equal":
      return { code: "null" };
    //   return ({ code: "null" });
    case "KeyA":
      return { code: "KeyE" };
    case "KeyB":
      return { code: "KeyF" };
    case "KeyC":
      return { code: "KeyG" };
    case "KeyD":
      return { code: "KeyA" };
    case "KeyE":
      return { code: "KeyB" };
    case "KeyF":
      return { code: "KeyC" };
    case "KeyG":
      return { code: "KeyD" };
    default:
      return { code: "null" };
  }
};
