import React from "react";
import { ThemeProvider } from "styled-components";

// Create all constants for our theme here

export const theme = {
  colors: {
    primary: "#0029FA",
    secondary: "#8D07F6",
    tertiary: "#20F575",
    info: "#FFFF05",
    muted: "#A6ABAB",
    dark: "#3D3D3D",
    secondaryComplement: "#F5A520",
    secondarySquare: "#14F5ED",
  },
  fontSizes: {
    small: "1rem",
    medium: "1.6rem",
    large: "2.4rem",
    xLarge: "3.6rem",
    xxLarge: "5rem",
    huge: "8rem",
    gigantic: "12rem",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
