import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";
// import styled from "styled-components/macro";
import Form from "../Form/Form";
import FormHolder from "../Form/FormHolder";
// import axiosInstance from "../../axiosAPI";

import { loginStart } from "../../store/actions/auth";

import Spinner from "../UI/Spinner";

const Login = (props) => {
  const { startLogin, isAuthenticated, loginStarted, history } = props;
  useLayoutEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated, history]);

  const handleSubmit = async (event, formState) => {
    event.preventDefault();
    console.log("trying login");
    startLogin(formState.username, formState.password);
  };
  const fields = [
    { name: "username", type: "text", label: "Username" },
    { name: "password", type: "password", label: "Password" },
  ];
  const formName = "Log In";

  let form;
  if (!loginStarted) {
    form = (
      <Form
        fields={fields}
        formName={formName}
        submitHandler={handleSubmit}
        includeSignup
        includePasswordReset
      />
    );
  } else {
    form = <Spinner />;
  }

  return <FormHolder>{form}</FormHolder>;
};

const mapStateToProps = (state) => ({
  loginStarted: state.auth.loginStarted,
  authFailed: state.auth.authFailed,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startLogin: (username, password) =>
      dispatch(loginStart(username, password, ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
