import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";

import Brand from "./NavbarLogo.js";
import NavLinkList from "./NavLinkList";
// const logo = require("./img/logo.png");

const Navbar = styled.nav`
  position: sticky;
  display: flex;
  /* height: 8rem; */
  top: 0;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.dark};
  box-shadow: 0 3px 10px 10px rgba(0, 0, 0, 0.1);
`;

const NavHeader = (props) => {
  // TODO - make this dynamic based on authentication, etc.

  const linksUnauhenticated = [
    { text: "Home", href: "/" },
    { text: "Log In", href: "/login/" },
    // { text: "Signup", href: "/signup/" },
  ];

  const linksAuthenticated = [
    { text: "Home", href: "/" },
    { text: "Lessons", href: "/lessons" },
    // { text: "Profile", href: "/profile" },
    { text: "Log Out", href: "/logout/" },
  ];

  return (
    <Navbar>
      <Brand>
        {/* <img src={logo}></img> */}
        Mrs G's Music Suite
      </Brand>
      <NavLinkList
        links={props.isAuthenticated ? linksAuthenticated : linksUnauhenticated}
      />
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);
